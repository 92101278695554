import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BildHeader from "../../images/services/augenvorsorge/header.jpg"
import BildLinks from "../../images/services/augenvorsorge/links.jpg"
import BildRechts from "../../images/services/augenvorsorge/rechts.jpg"
import SubNavigation from "../../components/SubNavServices"
import PlayIcon from "../../assets/play-icon.svg"
import Termin from "../../components/Termin"

const VideoLink = styled.a`
  display: block;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${PlayIcon});
    background-position: center;
    background-size: 60px;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: 0.05s background-size;
  }

  &:hover {
    &:after {
      opacity: 0.7;
      background-size: 67px;
    }
  }
`

const Augenvorsorge: React.FC = () => {
  const siteTitle = "Augenvorsorge – Dienstleistungen"
  const [isSubMenuOpen, setSubMenuOpen] = useState(false)

  return (
    <Layout title={siteTitle}>
      <SEO title={siteTitle} />
      <section className="wrapper">
        <div className="headerImage">
          <img src={BildHeader} />
        </div>
      </section>
      <Termin />
      <SubNavigation
        isSubMenuOpen={isSubMenuOpen}
        setSubMenuOpen={setSubMenuOpen}
        activeMenuText="Augenvorsorge"
      />
      <section className="wrapper greyColor content">
        <h1>Augenvorsorge</h1>
        <h2 className="contentSubtitle">SCHWARZ Optik – Seit 1992 in Heiden</h2>
        <div className="contentColumns">
          <div className="contentColumn">
            <div className="imageCollection">
              <div className="imageColumn">
                <div className="imageRow">
                  <img src={BildLinks} />
                </div>
              </div>
              <div className="imageColumn">
                <div className="imageRow">
                  <VideoLink
                    href="https://www.srf.ch/sendungen/puls/koerper/schielen-bei-kindern-die-augen-besser-frueh-auf-achse-bringen"
                    target="_blank"
                  >
                    <img src={BildRechts} />
                  </VideoLink>
                </div>
              </div>
            </div>
            <p className="imageCaption">
              <strong>Schielen bei Kindern –</strong> Sehen Sie den SRF-Beitrag
            </p>
          </div>
          <div className="contentColumn">
            <h2>Vorbeugende Beurteilung der Sehfunktion</h2>
            <p>
              Ihre Sehleistung beeinflusst massgeblich Ihre physische Leistung
              in Arbeit Sport und Freizeit. Das rechtzeitige Erkennen von
              Sehstörungen und das Ergreifen entsprechender Massnahmen können
              vorbeugen.
            </p>
            <h2>Sonnen- und Augenschutz</h2>
            <p>
              Wer sich in Beruf, Freizeit oder Sport länger im Freien aufhält,
              sollte seine Augen angepasst schützen: Vor Blendung und vor allem
              vor UV-Strahlung. Wer Auto fährt, hat ganz andere Schutz- und
              Sehanforderungen als beim Skifahren, Joggen oder Wassersport.
            </p>
            <h2>Seh- und Augenpflege</h2>
            <p>
              Wahrscheinlich haben Sie schon diese Erfahrung gemacht: Sie sitzen
              stundenlang vor dem Fernseher/PC oder lesen ein Buch und haben
              immer grössere Mühe, die Gestalten oder die Buchstaben zu
              erkennen. Wir unterstützen Sie mit der optimalen Sehhilfe für den
              jeweiligen Zweck!
            </p>
            <p>
              Unsere tägliche Arbeit als Optometristen zeigt, leichte
              Sehstörungen bei Kindern werden durch die Eltern oft viel zu spät
              erkannt. Das Team von SCHWARZ Optik ist speziell ausgebildet, um
              Abweichungen zu messen und mit Spezialgläsern zu korrigieren. So
              zählt bei uns zum Beispiel auch die Messung des Sehgleichgewichtes
              zum Standard einer umfassenden Augenprüfung.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Augenvorsorge
